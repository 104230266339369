import * as Yup from 'yup'
import axios from 'axios'
import { navigate } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { INTEGRATIONS_API } from '../config/constants'

export const initialValues = {
  name: '',
  last_name: '',
  company: '',
  email: '',
  password: '',
  department: '',
  access_cad_3d_data: false,
  personal_onboarding: false,
  reach_out: false,
}

export const schema = Yup.object().shape({
  email: Yup.string().required('validation:required').email('validation:email'),
  name: Yup.string().required('validation:required').min(4, 'validation:short'),
  last_name: Yup.string()
    .required('validation:required')
    .min(4, 'validation:short'),
  company: Yup.string()
    .required('validation:required')
    .min(4, 'validation:short'),
  password: Yup.string()
    .required('validation:required')
    .min(8, 'validation:short'),
  // access_cad_3d_data: Yup.string(),
  // personal_onboarding: Yup.boolean(),
  reach_out: Yup.boolean()
    .required('validation:required')
    .test('is-true', 'validation:required', v => v),
})

export function handleSubmit(origin) {
  return async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await axios.post(`${INTEGRATIONS_API}/enter-platform`, values)
      setSubmitting(false)
      trackCustomEvent({
        category: 'Website Events',
        action: origin,
      })
      navigate('/register-success')
    } catch (e) {
      if (
        e.response?.data &&
        e.response.data.error ===
          'UsernameExistsException: An account with the given email already exists.'
      ) {
        alert('An account with the given email already exists.')
      } else {
        alert('Something went wrong. Please, try again later.')
      }
    }
  }
}
