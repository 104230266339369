import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import DemoPage from '../ui/pages/DemoPage'
import { Formik } from 'formik'
import {
  schema,
  handleSubmit,
  initialValues,
} from '../integrations/enter-platform'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'

const Demo = props => (
  <>
    <HelmetComponent
      title={metaTags.demoTitle}
      description={metaTags.demo}
      page="demo"
    />
    <AppWrapper {...props}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit('Demo page')}
        validationSchema={schema}
      >
        {formikProps => <DemoPage formik={formikProps} />}
      </Formik>
    </AppWrapper>
  </>
)

export default Demo
